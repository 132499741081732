export const theme = {
  breakpoints: {
    small: '730px',
  },

  color: {
    darkGrey: '#333333',
    grey: '#666',
  },

  // prettier-ignore
  font: {
    weight: {
      '300': 300,
      '400': 400
    },

    size: {
      xxl: '2.5rem',
      xl: '1.8rem',
      l: '1.5rem',
      xs: '0.93rem'
    }
  },

  header: {
    height: '150px',
  },

  footer: {
    height: '200px',
    color: '#5c5c5c',
  },
}
