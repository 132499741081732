import React from 'react'
import { ThemeProvider } from 'styled-components'
import { CssReset } from './src/styles/cssReset'
import { GlobalSettings } from './src/styles/globalSettings'
import { theme } from './src/styles/theme'

export const pageWrapper = ({ element }) => (
  <>
    <CssReset />
    <GlobalSettings />
    {element}
  </>
)

export const rootWrapper = ({ element }) => <ThemeProvider theme={theme}>{element}</ThemeProvider>
