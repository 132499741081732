import { createGlobalStyle } from 'styled-components'

export const GlobalSettings = createGlobalStyle`
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }
  
  html, body {
    background-color: #FAFAFA;
    font-family: 'Roboto', 'Hiragino Sans', 'Meiryo', sans-serif;
  }
  
  ul, ol {
    list-style: none;
  }
`
